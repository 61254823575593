import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import MisClient from "../../models/MisClient";
import MisLawsuit from "../../models/MisLawsuit";

export default function ClientPage() {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const { misClients, misClientLoading } = useCollectionContext();

  /***
   * set client
   */
  useEffect(() => {
    if (misClients) {
      const c = misClients.find((client) => client.id == id);
      setClient(c);
    }
  }, [id]);

  /***
   * add lawsuite
   */
  const addLawsuite = () => {
    const misClient = MisClient.fromData(client);

    console.log(misClient);

    // lawsuite
    const misLawsuit = new MisLawsuit();

    misClient.addLawsuit(misLawsuit);
    misClient.commit().then((ref) => {
      setClient({ ...misClient });
    });
  };

  const navigate = useNavigate();
  const goToLawsuit = (e, lawsuitId) => {
    navigate(`/lawsuit/${lawsuitId}`);
  };

  if (!client) {
    <div>loading</div>;
  }

  return (
    <div className="flex w-[1000px] mx-auto bg-red-400">
      <div className="w-full">
        <h2 className="pt-2">Client</h2>
        <div>{id}</div>
        <div>{client?.id}</div>
        <div>{client?.name}</div>

        <div className="flex items-center w-full h-[40px]">
          <h2 className="h-full flex items-center">Lawsuits</h2>
          <div
            onClick={addLawsuite}
            className="flex items-center ml-auto mr-2 cursor-pointer h-full"
          >
            <div>[add lawsuite]</div>
          </div>
        </div>

        {/* horizontal break */}
        <hr className="border-gray-800 border-[1px] my-1" />

        <div className="flex flex-col gap-1">
          {client?.lawsuits.map((lawsuit) => (
            <div key={lawsuit.id}>
              <div
                onClick={(e) => goToLawsuit(e, lawsuit.id)}
                className="bg-gray-400 h-[40px] items-center p-2 cursor-pointer"
              >
                <div>{lawsuit.id}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
