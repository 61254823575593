import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function LawsuitPage() {
  const { id } = useParams();
  const [lawsuit, setLawsuit] = useState(null);

  /***
   * handle add draft
   */
  const handleAddDraft = () => {};

  return (
    <div className="flex w-[1000px] bg-red-400 mx-auto">
      <div className="w-full">
        <div>lawsuitId: {id}</div>
        <div className="flex items-center">
          <div>drafts</div>
          <div onClick={handleAddDraft} className="ml-auto mr-2 cursor-pointer">
            [add draft]
          </div>
        </div>
      </div>
    </div>
  );
}
