import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import MisLawsuit from "./MisLawsuit";

class MisClient {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  avatar = "";
  date = new Date();
  lawsuits = [];
  documents = [];
  inbox = { items: [] };

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  static fromData = (data) => {
    const p = new MisClient(data["id"]);
    p.name = data["name"];
    p.number = data["number"];
    p.avatar = data["avatar"];
    p.date = data["date"];
    p.lawsuits = data["lawsuits"]?.map((lawsuiteData) =>
      MisLawsuit.fromData(lawsuiteData)
    );

    p.documents = data["documents"];
    p.inbox = data["inbox"];

    return p;
  };

  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      number: this.number,
      name: this.name,
      avatar: this.avatar,
      date: this.date,
      lawsuits: this.lawsuits.map((lawsuit) => lawsuit.data()),

      documents: this.documents,
      inbox: this.inbox,
    };
  };

  addLawsuit = (misLawsuit) => {
    console.log("############################");
    console.log(this.lawsuits);
    this.lawsuits.push(misLawsuit);
  };

  commit = () => {
    const d = this.data();

    console.log(d);

    const docRef = doc(db, "misClients", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}
export default MisClient;
