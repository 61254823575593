import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

class HexStudioProject {
  id = ""; // generated by firebase on adding object to firestore
  studioId = "";
  created = new Date();
  name = "";
  members = [];
  worksets = [];
  drawings = [];
  files = [];
  inbox = { items: [] };

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  /***
   * from data
   */
  static fromData = (data) => {
    const u = new HexStudioProject(data["id"]);
    u.studioId = data["studioId"];
    u.created = data["created"];
    u.drawings = data["drawings"];
    u.name = data["name"];
    u.members = data["members"];
    u.worksets = data["worksets"];
    u.files = data["files"];
    u.inbox = data["inbox"];
    return u;
  };

  addDrawing = (drawing) => {
    this.drawings.push(drawing);
  };

  addInboxItem = (item) => {
    this.inbox.items.push(item);
  };

  removeInboxItem = (itemId) => {
    this.inbox.items = this.inbox.items.filter((item) => item.id != itemId);
  };

  markInboxItemAsRead = (itemId) => {
    const item = this.inbox.items.find((item) => item.id === itemId);
    item.isRead = true;
  };

  markInboxItemUnread = (itemId) => {
    const item = this.inbox.items.find((item) => item.id === itemId);
    item.isRead = false;
  };

  addFile = (file) => {
    this.files.push(file);
  };

  removeFileWithId = (fileId) => {
    this.files = this.files.filter((file) => file.id != fileId);
  };

  numOfUnreadInboxItems = () => {
    return this.inbox.items.filter((item) => !item.isRead).length;
  };

  removeDrawing = (drawingId) => {
    this.drawings = this.drawings.filter((drawing) => drawing.id != drawingId);
  };

  addMembers = (newIds) => {
    this.members = [...this.members, ...newIds];
  };

  /***
   * data
   */
  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      studioId: this.studioId,
      created: this.created,
      name: this.name,
      members: this.members,
      worksets: this.worksets,
      drawings: this.drawings,
      files: this.files,
      inbox: this.inbox,
    };
  };

  drawingsWithId = (id) => {
    return this.drawings.find((d) => d.id == id);
  };

  setDescriptionForFileWithId = (newDescription, fileId) => {
    const f = this.files.find((file) => file.id == fileId);
    f != null
      ? (f.description = newDescription)
      : (f.description = "file not found");
  };

  setNameForFileWithId = (newName, fileId) => {
    //
    const f = this.files.find((file) => file.id == fileId);
    f != null ? (f.name = newName) : (f.name = "file not found");
  };

  flushMembers = () => {
    this.members = [];
  };

  removeMemberWithId = (memberId) => {
    console.log("before remove: @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log(this.members);
    this.members = this.members.filter((member) => member != memberId);
    console.log("after remove: @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log(this.members);
  };

  commit = () => {
    const d = this.data();

    console.log(d);

    const docRef = doc(db, "studioProjects", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}
export default HexStudioProject;
