import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStudioContext } from "../../../hooks/useStudioContext";
import HexStudioProject from "../../../models/HexStudioProject";

import { handleDisplayDate } from "../../../utilities/DateManager";

export default function StudioProjectInboxStack({ project, users }) {
  const { currentStudentId } = useStudioContext();
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-1">
        {project.inbox.items
          .sort((a, b) => b.date.toMillis() - a.date.toMillis())
          .map((item) => (
            <div key={item.id}>
              <SPInboxRow item={item} project={project} />
            </div>
          ))}
      </div>
    </div>
  );
}

const SPInboxRow = ({ item, project }) => {
  const { resoveNameForId } = useStudioContext();

  const handleDeleteItem = (e, item) => {
    if (window.confirm("delete inbox item")) {
      const hexProject = HexStudioProject.fromData(project);
      hexProject.removeInboxItem(item.id);
      hexProject.commit().then((ref) => {
        toast.success("inbox item deleted");
      });
    }
  };

  const handleMarkUnread = (e, item) => {
    e.stopPropagation();
    const hexProject = HexStudioProject.fromData(project);
    hexProject.markInboxItemUnread(item.id);
    hexProject.commit().then((ref) => {
      toast.success("item marked unread");
    });
  };

  const navigate = useNavigate();
  const gotoDrawing = () => {
    const drawingId = item.drawingId;
    const projectId = item.projectId;

    // later must validiate if the project and its drawing exists

    const hexProject = HexStudioProject.fromData(project);
    hexProject.markInboxItemAsRead(item.id);
    hexProject.commit().then((ref) => {
      navigate(`/canvas/${drawingId}/${projectId}/${1}`);
    });
  };

  /*
  const handleClick = (e) => {
    e.stopPropagation();

    // mark as read
    const hexUser = HexUser.fromData(user);
    hexUser.markItemAsRead(item.id);

    let drawingId;
    let projectId;

    try {
      projectId = project.id;
    } catch (e) {
      console.log(e);
      toast.error("the project may have been deleted");
      return;
    }

    try {
      drawingId = drawing.id;
    } catch (e) {
      console.log(e);
      toast.error("the drawing may have been deleted");
      return;
    }

    // console.log(drawingId, projectId);
    navigate(`/canvas/${drawingId}/${projectId}/${0}`);

    // commit
    hexUser.commit();
  };
  */

  return (
    <div
      onClick={gotoDrawing}
      className="flex items-center bg-gray-400 min-h-[60px] p-2 cursor-pointer"
    >
      <div
        className={`w-[20px] h-[20px] ${
          item.isRead
            ? "bg-gray-300 border-2 border-gray-700/50"
            : "bg-orange-500"
        } rounded-full mx-6`}
      ></div>
      <div className="">
        <div className="font-bold">{resoveNameForId(item.senderId)}</div>
        <div>{handleDisplayDate(item.date)}</div>
        <div>{item.id}</div>
      </div>
      <div
        onClick={(e) => handleMarkUnread(e, item)}
        className="flex items-center ml-auto cursor-pointer"
      >
        <div>[unread]</div>
      </div>
      <div
        onClick={(e) => handleDeleteItem(e, item)}
        className="flex items-center mx-2 cursor-pointer"
      >
        <div>[delete]</div>
      </div>
    </div>
  );
};
