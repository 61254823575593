import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import HexStudioProject from "../../models/HexStudioProject";
import { useDocument } from "../../hooks/useDocument";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import Select, { components } from "react-select";

import { uuidv4 as uuid } from "@firebase/util";
import CircularProgressWithLabel from "../../components/progress/CircularProgressWithLabel";
import HexDialogButtons from "../../components/dialogs/HexDialogButtons";
import SelectField from "../../fields/SelectField";

import { SELECT_ROLES } from "../../models/Enums";
import StudioProjectDrawingStack from "./components/StudioProjectDrawingStack";
import StudioProjectMemberStack from "./components/StudioProjectMemberStack";
import StudioProjectFileStack from "./components/StudioProjectFileStack";
import { useUploadDocument } from "../../hooks/useUploadDocument";
import { useStudioContext } from "../../hooks/useStudioContext";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import ProjectTab from "./components/ProjectTab";
import HexAddMemberDialog from "./forms/HexAddMemberDialog";
import HexDialog from "./forms/HexDialog";
import StudioProjectInboxStack from "./components/StudioProjectInboxStack";

const TabIndex = { Drawings: "0", Members: "1", Files: "2", Inbox: "3" };

/***
 * Studio Project Page
 */
export default function StudioProjectPage() {
  const { id } = useParams();
  const { document: project } = useDocument("studioProjects", id);
  const {
    currentStudentId,
    setCurrentStudentId,
    resolveSerial,
    members,
    setMembers,
    resoveNameForId,
    selectedTab,
    setSelectedTab,
  } = useStudioContext();
  const { setShowNav, resetCanavs } = useCanvasContext();
  const { users } = useCollectionContext();

  const { activeUser, userIs } = useAuthContext();
  const [data, setData] = useState({ name: "", serial: "" });
  const [memberOptions, setMemberOptions] = useState([]);
  const [activeDrawings, setActiveDrawings] = useState([]);

  // refs
  const inputfileRef = useRef();
  const inputDrawingRef = useRef();

  // uppload state
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { uploadImage } = useUploadDocument();

  /***
   * user with id
   */
  const userWithId = (memberId) => {
    return members.find((member) => member.id == memberId);
  };

  /***
   * show nav
   */
  useEffect(() => {
    setShowNav(true);
    resetCanavs();
  }, []);

  /***
   * set members
   */
  // useEffect(() => {
  //   if (users && activeUser) {
  //     const results = users.filter(
  //       (user) => user.studioId == activeUser.studioId && !user.isInstructor
  //     );

  //     // sort results alphabatecally according to displayname
  //     const sortedResults = results.sort((a, b) =>
  //       a.displayname.localeCompare(b.displayname)
  //     );

  //     // set members
  //     setMembers(sortedResults);
  //     // setCurrentStudentId(sortedResults[0].id || "");
  //   }
  // }, [users]);

  // /***
  //  * set members
  //  */
  // useEffect(() => {
  //   if (users && activeUser) {
  //     const results = users.filter(
  //       (user) => user.studioId == activeUser.studioId && !user.isInstructor
  //     );

  //     // sort results alphabatecally according to displayname
  //     const sortedResults = results.sort((a, b) =>
  //       a.displayname.localeCompare(b.displayname)
  //     );

  //     // set members
  //     setMembers(sortedResults);
  //     // setCurrentStudentId(sortedResults[0].id || "");
  //   }
  // }, [users]);

  /***
   * set member options + current student
   */
  useEffect(() => {
    if (activeUser.isInstructor) {
      if (project) {
        let mOptions = [];
        // make sure members is created nad populated
        if (members.length > 0) {
          mOptions = project.members
            .map((memberId) => {
              return userWithId(memberId);
            })
            .map((memberObject) => {
              return {
                value: memberObject.id,
                label: memberObject.displayname,
              };
            });
        }

        // set member options
        setMemberOptions([...mOptions]);
      }
    }
  }, [project, members]);

  // if user is student, set the currentStudentId
  useEffect(() => {
    // if user is student
    if (!activeUser.isInstructor) {
      setCurrentStudentId(activeUser.id); // don't worry. will not go into infinate loop
    }
  }, []);

  // const [memberOption, setMemberOption] = useState("");

  // Load the initial value of `memberOption` from localStorage or set to an empty string if not present
  const [memberOption, setMemberOption] = useState(() => {
    const savedMemberOption = localStorage.getItem("memberOption");
    return savedMemberOption !== null
      ? JSON.parse(savedMemberOption)
      : { value: "", label: "" };
  });

  // Save `memberOption` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("memberOption", JSON.stringify(memberOption));
  }, [memberOption]);

  const resolveSelectOption = (option) => {
    // set current
    setMemberOption(option);
    setCurrentStudentId(option.value);
  };

  /***
   * handle edit click
   */
  const handleEditClick = (e) => {
    // toast
    setOpenSetName(true);
    setData({ ...data, name: "" });
  };

  /***
   * handle set name
   */
  const handleSetName = (agree) => {
    if (agree) {
      const hexProject = HexStudioProject.fromData(project);
      hexProject.name = data.name;
      hexProject.commit().then((ref) => {
        toast.success("name updated");
        setOpenSetName(false);
      });
    } else {
      setOpenSetName(false);
    }
  };

  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [openSetName, setOpenSetName] = useState(false);

  const handelOpenAddMemberDialog = () => {
    setOpenSetName(true);
  };

  const handleAddMembers = () => {
    const results = users.filter(
      (user) => user.studioId == activeUser.studioId
    );
    const memberIds = results.map((r) => {
      return r.id;
    });
    const hexProject = HexStudioProject.fromData(project);
    hexProject.addMembers(memberIds);
    hexProject.commit();
  };

  const resolveAddMemberDialog = async (agree, memberState) => {
    if (agree) {
      // get ids to add
      const results = memberState.filter((m) => m.checked == true);
      const memberIds = results.map((r) => {
        return r.member.id;
      });

      // validate
      const members = project.members;
      const validIds = memberIds.filter(
        (memberId) => !members.includes(memberId)
      );

      // operate on HexStudioProject
      const hexProject = HexStudioProject.fromData(project);
      hexProject.addMembers(validIds);
      hexProject.commit().then((ref) => {
        toast.success("members added");
      });

      // close
      setOpenAddMemberDialog(false);
    } else {
      toast.error("dismiss");
      setOpenAddMemberDialog(false);
    }
  };

  const handleRemoveMembers = () => {
    if (window.confirm("remove all members")) {
      const hexProject = HexStudioProject.fromData(project);
      hexProject.flushMembers();
      hexProject.commit();
    }
  };

  const resolveAddDrwing = () => {
    const hexStudioProject = HexStudioProject.fromData(project);

    let serial = resolveSerial(project);

    // drawing
    const d = {
      id: uuid(),
      studioId: activeUser.studioId,
      projectId: project.id,
      studentId: currentStudentId,
      sender: activeUser.isInstructor ? activeUser.id : currentStudentId,
      serial: serial,
      date: new Date(),
      type: "submission",
      url: "",
      isRemoved: false,
    };
    hexStudioProject.addDrawing(d);
    hexStudioProject.commit();
  };

  // const resoveName = () => {
  //   const member = members.find((m) => m.id == currentStudentId);
  //   return member?.displayname || "no name";
  // };

  const resolveStudentName = (studentId) => {
    const member = users.find((m) => m.id == studentId);
    return member.displayname;
  };

  const resolveNumberOfDrawings = () => {
    const r = project.drawings.filter(
      (drawing) => drawing.studentId == currentStudentId
    );
    return r.length;
  };

  const resolveNumberOfUnread = () => {
    const hexProject = HexStudioProject.fromData(project);
    return hexProject.numOfUnreadInboxItems();
  };

  const resolveTabClick = (selectedIndex) => {
    setSelectedTab(selectedIndex);
  };

  const resolveNumberOfMembers = () => {
    return project.members.length;
  };

  const resolveNumberOfFiles = () => {
    return project.files.length;
  };

  /***
   * handle add click
   */
  const handleAddSubmissionClick = () => {
    inputfileRef.current.click();
  };

  /***
   * handle upload pdf
   */
  const handleUploadSubmissionPdf = async (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // Example: 5 MB size limit

    // validate fire size
    if (file.size > maxSizeInBytes) {
      toast.error("your file exceeds the size limit of 5MB.");
    } else {
      const fileName = file.name || "studio drawings";
      setData({ ...data, file: file });
      if (!project) return;

      // Get the file size in bytes
      const sizeInBytes = file.size;

      // loading spinner
      setUploading(true);
      setProgress(0);

      // 2. upload pdf
      await uploadImage(file, "studio/pdf", setProgress).then(
        async (pdfUrl) => {
          const url = pdfUrl.replace(
            "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
            "ik.imagekit.io/habiatuq/hexpo"
          );

          const hexStudioProject = HexStudioProject.fromData(project);

          let serial = resolveSerial(project);

          // drawing
          const d = {
            id: uuid(),
            studioId: activeUser.studioId,
            projectId: project.id,
            studentId: currentStudentId,
            sender: activeUser.isInstructor ? activeUser.id : currentStudentId,
            serial: serial,
            date: new Date(),
            type: "submission",
            url: url,
            isRemoved: false,
          };
          hexStudioProject.addDrawing(d);

          // add item to inbox
          const inboxItem = {
            id: uuid(),
            studioId: activeUser.studioId,
            projectId: project.id,
            drawingId: d.id,
            senderId: activeUser.id,
            date: d.date,
            isRead: false,
          };
          hexStudioProject.addInboxItem(inboxItem);

          hexStudioProject.commit().then((ref) => {
            toast.success("drawing uploaded");
            setProgress(0);
            setUploading(false);
            e.target.value = null;
          });
        }
      );
    }
  };

  const handleUploadProjectFilePdf = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name || "studio files";
    setData({ ...data, file: file });
    if (!project) return;

    // loading spinner
    setUploading(true);
    setProgress(0);

    // 2. upload pdf
    await uploadImage(file, "studio/pdf", setProgress).then(async (pdfUrl) => {
      const url = pdfUrl.replace(
        "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
        "ik.imagekit.io/habiatuq/hexpo"
      );

      const hexStudioProject = HexStudioProject.fromData(project);

      // drawing
      const projectFile = {
        id: uuid(),
        studioId: activeUser.studioId,
        projectId: project.id,
        sender: activeUser.isInstructor ? activeUser.id : currentStudentId,
        name: fileName,
        description: "",
        serial: "",
        date: new Date(),
        type: "resource",
        url: url,
        isRemoved: false,
      };

      hexStudioProject.addFile(projectFile);

      hexStudioProject.commit().then((ref) => {
        toast.success("files uploaded");
        setProgress(0);
        setUploading(false);
        e.target.value = null;
      });
    });
  };

  const navigate = useNavigate();
  const gotBack = () => {
    navigate(-1);
  };

  if (!project || members.length == 0)
    return (
      <div>
        <div>loading</div>
      </div>
    );

  return (
    <div className="w-[1000px] mx-auto mt-2">
      {/* Progress spinner */}
      {uploading && (
        <div className="flex items-center gap-1 absolute top-1/2 left-1/2 bg-black/75 p-10 rounded-lg text-white transform -translate-x-1/2 -translate-y-1/2">
          uploading drawing
          <CircularProgressWithLabel value={progress} />
        </div>
      )}

      {/* dialog */}
      <HexDialog
        open={openSetName}
        data={data}
        setData={setData}
        handleDialogButton={handleSetName}
      />

      <HexAddMemberDialog
        open={openAddMemberDialog}
        data={data}
        setData={setData}
        handleDialogButton={resolveAddMemberDialog}
        members={members}
        project={project}
      />
      <div>number of drawings: {project?.drawings.length}</div>
      <div className="flex items-center h-[60px]">
        <div
          onClick={gotBack}
          className="text-3xl font-bold cursor-pointe bg-gray-300 rounded w-full p-2 cursor-pointer"
        >
          {project.name}
        </div>
        {activeUser.isInstructor && (
          <div
            onClick={handleEditClick}
            className="flex items-center justify-center ml-2 cursor-pointer bg-gray-300 rounded h-[50px] min-w-[50px] px-3"
          >
            <div>[edit]</div>
          </div>
        )}
      </div>

      <div className="flex items-center gap-2 h-[60xp] my-4">
        {/* drawings tab */}
        <div className="min-w-[170px]">
          <ProjectTab
            index={TabIndex.Drawings}
            label={"drawings"}
            selectedTab={selectedTab}
            onClick={resolveTabClick}
            numberOfItems={resolveNumberOfDrawings()}
          />
        </div>
        <ProjectTab
          index={TabIndex.Files}
          label={"files"}
          selectedTab={selectedTab}
          onClick={resolveTabClick}
          numberOfItems={resolveNumberOfFiles()}
        />

        {/* inbox tab */}
        {activeUser.isInstructor && (
          <div className="">
            <ProjectTab
              index={TabIndex.Inbox}
              label={"inbox"}
              selectedTab={selectedTab}
              onClick={resolveTabClick}
              numberOfItems={resolveNumberOfUnread()}
            />
          </div>
        )}

        {/* members tab */}
        {activeUser.isInstructor && (
          <ProjectTab
            index={TabIndex.Members}
            label={"members"}
            selectedTab={selectedTab}
            onClick={resolveTabClick}
            numberOfItems={resolveNumberOfMembers()}
          />
        )}

        {/* drawing tab actions */}
        {selectedTab == 0 && project.members.length > 0 && (
          <div className="flex items-center w-full">
            <div
              onClick={handleAddSubmissionClick}
              // onClick={resolveAddDrwing}
              className="ml-auto cursor-pointer  bg-blue-500 p-2 rounded hover:bg-blue-400"
            >
              <div className="pointer-events-none">
                <input
                  ref={inputfileRef}
                  type="file"
                  accept="application/pdf" // This limits the file type to PDF
                  id="file"
                  onChange={handleUploadSubmissionPdf}
                  className="hidden"
                />
                <div className={`flex "text-black" h-full`}>[add drawing]</div>
              </div>
            </div>
          </div>
        )}

        {/* member tab actions */}
        {selectedTab == 1 && (
          <div className="flex items-center w-full gap-2">
            <div
              onClick={() => setOpenAddMemberDialog(true)}
              className="ml-auto cursor-pointer font-bold bg-blue-500 p-2 rounded hover:bg-blue-400"
            >
              [add member]
            </div>
            {project.members.length > 0 && (
              <div
                onClick={handleRemoveMembers}
                className="cursor-pointer font-bold bg-orange-400 p-2 rounded hover:bg-orange-300"
              >
                [remove all members]
              </div>
            )}
          </div>
        )}

        {/* files tab actions */}
        {selectedTab == 2 && (
          <div
            className={`flex items-center w-full ${
              activeUser.isInstructor ? "" : "invisible"
            }`}
          >
            <div
              onClick={handleAddSubmissionClick}
              // onClick={resolveAddDrwing}
              className="ml-auto cursor-pointer  bg-blue-500 p-2 rounded hover:bg-blue-400"
            >
              <div className="pointer-events-none">
                <input
                  ref={inputfileRef}
                  type="file"
                  accept="application/pdf" // This limits the file type to PDF
                  id="file"
                  onChange={handleUploadProjectFilePdf}
                  className="hidden"
                />
                <div className={`flex "text-black" h-full`}>[add file]</div>
              </div>
            </div>
          </div>
        )}

        {/* inbox tab actions */}
        {selectedTab == TabIndex.Inbox && (
          <div className={`flex items-center w-full invisible h-[40px]`}>
            <div className="ml-auto cursor-pointer  bg-blue-500 p-2 rounded hover:bg-blue-400"></div>
          </div>
        )}
      </div>

      {/* horizontal break */}
      <hr className="border-gray-800 border-[1px] my-1" />

      {/* drawing stack */}
      {selectedTab == TabIndex.Drawings && (
        <div>
          <div className="">
            <div className="flex flex-col items-center my-4">
              <div className="w-[1000px]">
                <SelectField
                  inputWidth={600}
                  fullWidth={true}
                  options={memberOptions}
                  data={memberOption}
                  setData={resolveSelectOption}
                  isActive={true}
                  production={true}
                  showLabel={false}
                />
              </div>

              <div className="hidden">
                {userIs(SELECT_ROLES.MASTER) && (
                  <div>
                    <div className="py-2">
                      <HexSelect
                        option={memberOption}
                        options={memberOptions}
                        onChange={resolveSelectOption}
                      />
                    </div>
                    <div>studentId: {currentStudentId}</div>
                    {/* 
                  <div>activeUser: {activeUser.id}</div>
                  <div className="flex mt-10">
                    <div>
                      {users
                        .filter(
                          (u) =>
                            u.studioId == activeUser.studioId && !u.isInstructor
                        )
                        .map((u) => (
                          <div key={u.id}>
                            <div>{u.displayname}</div>
                            <div>{u.id}</div>
                          </div>
                        ))}
                    </div>
                    <div className="ml-4">
                      {members
                        .filter(
                          (u) =>
                            u.studioId == activeUser.studioId && !u.isInstructor
                        )
                        .map((u) => (
                          <div key={u.id}>
                            <div>{u.displayname}</div>
                            <div>{u.id}</div>
                          </div>
                        ))}
                    </div>
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <StudioProjectDrawingStack project={project} users={users} />
        </div>
      )}

      {/* member stack */}
      {selectedTab == 1 && (
        <StudioProjectMemberStack project={project} users={users} />
      )}

      {/* files stack */}
      {selectedTab == 2 && <StudioProjectFileStack project={project} />}

      {/* inbox stack */}
      {selectedTab == TabIndex.Inbox && (
        <StudioProjectInboxStack project={project} />
      )}
    </div>
  );
}

/***
 * hex Select
 */
const HexSelect = ({ option, options, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target);
  };

  return (
    <select
      className="h-[40px] w-[300px] border border-gray-400 rounded-md p-2"
      value={option.value}
      onChange={handleChange}
    >
      <option value="" disabled>
        Select an option
      </option>
      {options
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};
