export const SELECT_ROLES = {
  MASTER: { value: "master", label: "Master" },
  STUDENT: { value: "student", label: "Student" },
  ADMIN: { value: "admin", label: "Label" },
};

export const STAGES = {
  DESIGN: "design",
  REVIEW: "review",
  PRESENT: "present",
};

export const STACKS = {
  STUDIO: "studio",
  PRINCILE: "principle",
  CLIENT: "client",
};

export const DrawingType = {
  Blank: { value: "blank", label: "Blank" },
  Feedback: { value: "feedback", label: "Feedback" },
  Submission: { value: "submission", label: "Submission" },
};

export const stageForStack = (stack) => {
  switch (stack) {
    case "studio":
      return "design";
      break;
    case "principle":
      return "review";
      break;
    default:
      return "presentation";
  }
};

export const resolveStage = (currentStack) => {
  switch (currentStack) {
    case "client":
      return "presentation";
      break;
    case "principle":
      return "review";
      break;
    default:
      return "design";
  }
};

export const ListItemType = { project: "project", collection: "collection" };

export const Roles = {
  MASTER: "master",
  ADMIN: "admin",
  MANAGER: "manager",
  USER: "user",
  GUEsT: "guest",
};

export const HexpoTools = { Draw: "draw", Paint: "paint", Erase: "erase" };

// https://www.rapidtables.com/web/color/RGB_Color.html

export const Colors = {
  // white, gray, back
  WHITE: { r: 255, b: 255, g: 255 },
  GRAY_100: { r: 192, b: 192, g: 192 },
  GRAY_200: { r: 128, b: 128, g: 128 },
  GRAY_300: { r: 64, b: 64, g: 64 },
  BLACK: { r: 0, b: 0, g: 0 },

  // red
  RED_100: { r: 255, b: 204, g: 204 },
  RED_200: { r: 255, b: 102, g: 153 },
  RED_300: { r: 255, b: 0, g: 0 },
  RED_400: { r: 153, b: 0, g: 0 },
  RED_500: { r: 51, b: 0, g: 0 },

  // orange
  ORANGE_100: { r: 255, g: 229, b: 204 },
  ORANGE_200: { r: 255, g: 178, b: 120 },
  ORANGE_300: { r: 255, g: 128, b: 0 },
  ORANGE_400: { r: 153, g: 76, b: 0 },
  ORANGE_500: { r: 51, g: 25, b: 0 },

  // blue

  // green
  GREEN_100: { r: 255, b: 204, g: 255 },
  GREEN_200: { r: 255, b: 102, g: 255 },
  GREEN_300: { r: 255, b: 0, g: 255 },
  GREEN_400: { r: 153, b: 0, g: 153 },
  GREEN_500: { r: 51, b: 0, g: 51 },

  // yellow
};

class Enums {
  static ListItemType = { project: "project", collection: "collection" };
  static TagOptions = [
    { value: "residential", label: "Residential" },
    { value: "chalete", label: "Chalete" },
    { value: "400m", label: "400m" },
    { value: "500m", label: "500m" },
    { value: "600m", label: "600m" },
    { value: "750m", label: "750m" },
  ];

  static RolePermissions = {
    master: ["read", "write", "delete"],
    admin: ["read", "write", "delete"],
    user: ["read"],
    guest: [],
  };

  static SelectRolePermissions = {
    master: [
      { value: "read", label: "read" },
      { value: "write", label: "write" },
      { value: "delete", label: "delete" },
    ],
    admin: [
      { value: "read", label: "read" },
      { value: "write", label: "write" },
      { value: "delete", label: "delete" },
    ],
    user: [{ value: "read", label: "read" }],
    guest: [],
  };

  static UserRoles = [
    { value: "master", label: "Maser" },
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
    { value: "user", label: "User" },
    { value: "guest", label: "Guest" },
    { value: "student", label: "Student" },
  ];

  static Permissions = [
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    { value: "delete", label: "Delete" },
    { value: "copy", label: "Copy" },
    { value: "studioAssist", label: "Studio Assist" },
  ];

  static SelectRoles = {
    MASTER: { value: "master", label: "Master" },
    ADMIN: { value: "admin", label: "Label" },
  };
}
export default Enums;
