import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CollectionContextProvider } from "./context/CollectionContext";
import { AuthContextProvider } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import { CanvasContextProvider } from "./context/CanvasContext";
import { StudioContextProvider } from "./context/StudioContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthContextProvider>
    <AppContextProvider>
      <CollectionContextProvider>
        <StudioContextProvider>
          <CanvasContextProvider>
            <App />
          </CanvasContextProvider>
        </StudioContextProvider>
      </CollectionContextProvider>
    </AppContextProvider>
  </AuthContextProvider>
);
