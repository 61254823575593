import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { DrawingType } from "./Enums";

class HexDrawing {
  id = ""; // generated by firebase on adding object to firestore
  projectId = "";
  entityId = "";
  pushedBy = "";
  serial = "0.0.0";
  size = 0;
  name = "";
  drawingType = DrawingType.Blank;
  number = "";
  date = null;
  description = "";
  url = "";
  stage = "";
  tags = [];
  //   revisions = [];

  constructor(_id = uuid()) {
    this.id = _id;
    this.date = new Date();
  }

  static fromData = (data) => {
    const p = new HexDrawing(data["id"]);
    p.number = data["number"];
    p.name = data["name"];
    p.entityId = data["entityId"];
    p.projectId = data["projectId"];
    p.date = data["date"];
    p.url = data["url"];
    p.serial = data["serial"];
    p.stage = data["stage"] || "";
    p.tags = data["tags"];
    p.pushedBy = data["pushedBy"];
    p.description = data["description"] || "";
    p.size = data["size"];
    p.drawingType = data["drawingType"] || DrawingType.Blank; // <--- this is required for older objects with now drawing type. otherwise saving will fail

    // p.revisions = data["revisions"]?.map((revision) =>
    //   Revision.fromData(revision)
    // );

    return p;
  };

  data = () => {
    return {
      id: this.id,
      projectId: this.projectId,
      entityId: this.entityId,
      number: this.number,
      name: this.name,
      date: this.date,
      serial: this.serial,
      url: this.url,
      tags: this.tags,
      stage: this.stage,
      size: this.size,
      drawingType: this.drawingType || DrawingType.Blank,
      description: this.description || "",
      pushedBy: this.pushedBy,
      //   revisions: this.revisions.map((revision) => revision.data()),
    };
  };

  setStage = (key = "design") => {
    this.stage = key;
  };

  setSerial = (key = "0.0.0") => {
    this.serial = key;
  };

  dateString = () => {
    return new Date(this.date.seconds * 1000).toDateString();
  };

  addNewRevision = () => {
    // var r = new Revision();
    // this.revisions.push(r);
  };

  removeRevision = (rev) => {
    // this.revisions = this.revisions.filter((revision) => revision.id != rev.id);
  };

  addImgToRevisionId = (url, id) => {
    // const revision = this.revisions.filter((rev) => rev.id == id)[0];
    // revision.images.push(url);
  };

  updateRevisionImages = (imgUrlList, id) => {
    // const revision = this.revisions.filter((rev) => rev.id == id)[0];
    // revision.images = [...imgUrlList];
  };

  removeImageFromRevision = (url, id) => {
    // const revision = this.revisions.filter((rev) => rev.id == id)[0];
    // revision.removeImage(url);
  };

  latestRevision = () => {
    // return this.revisions[this.revisions.length - 1];
  };

  stageImages = () => {
    // const r = this.latestRevision();
    // return r.images;
  };

  /***
   * set drawing type
   */
  setType = (type) => {
    this.drawingType = type;
  };

  /***
   * commit
   */
  commit = () => {
    // const d = this.data();
    // const docRef = doc(db, "projects", this.id);
    // return setDoc(docRef, d, { merge: true });
  };
}

export default HexDrawing;
