import React from "react";
import HexStudioProject from "../../../models/HexStudioProject";
import { displayDate } from "../../../utilities/DateManager";
import HexCanvas from "../../canvas/HexCanvas";

// icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";

export default function StudioProjectDrwingRow({ drawing, project, users }) {
  const { activeUser } = useAuthContext();
  const navigate = useNavigate();

  /***
   * resolve studio name
   */
  const resolveStudentName = (studentId) => {
    const member = users.find((m) => m.id == studentId);
    if (member.id == "tmgR84n0hZEqxYZoEUdG") {
      return "Dr. Qutaibah Hamadah";
    }
    return member.displayname;
  };

  const resolveDate = (date) => {
    return displayDate(date);
  };

  const resolveDelete = (e, drawing) => {
    if (window.confirm("delete drawing")) {
      e.stopPropagation();
      const hexSTProject = HexStudioProject.fromData(project);
      hexSTProject.removeDrawing(drawing.id);
      hexSTProject.commit();
    }
  };

  // const resolveDownload = (drawingId) => {
  //   toast.success("downlaod drawing");
  // };

  const pushToCanvas = () => {
    if (activeUser.isInstructor) {
      navigate(`/canvas/${drawing.id}/${drawing.projectId}/${1}`);
    } else {
      toast.info("click the download button to download the drawing set");
    }
  };

  /***
   * handle display date
   */
  const handleDisplayDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toDateString()} : ${date.toLocaleTimeString()}`; // Format date and time as a string
    }
    return ""; // Return an empty string or a default value if the timestamp is invalid
  };

  const resolveDownload = async (e, drawing) => {
    e.stopPropagation();

    // drawing url
    const url = drawing.url;
    const date = handleDisplayDate(drawing.date);

    // Fetch the file as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href of the link
    const blobURL = window.URL.createObjectURL(blob);
    link.href = blobURL;

    // link.download = "your-filename.pdf";
    link.setAttribute("download", `${project.name}-${date}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resolveMonth = (date) => {
    return 1;
  };

  const resolveDay = (date) => {
    return 1;
  };

  return (
    <div>
      <div
        onClick={pushToCanvas}
        className={`flex gap-2 align-items-start ${
          drawing.type == "submission"
            ? "bg-gray-400 hover:bg-gray-500/45"
            : "bg-orange-500 hover:bg-orange-500/65"
        } min-h-[80px] cursor-pointer p-2`}
      >
        {/* {activeUser.isInstructor && (
          <div className="font-bold ml-[120px]">
            {resolveStudentName(drawing.studentId)}
          </div>
        )} */}

        <h1 className="flex items-center justify-center font-bold w-[150px] text-white bg-black/50 rounded-lg px-2 py-2 border-[1px] border-white">
          <div>{drawing.serial}</div>
        </h1>

        <div className="flex flex-col">
          {activeUser.isInstructor && (
            <div className="font-bold">
              {resolveStudentName(drawing.studentId)}
            </div>
          )}
          <div className="font-bold">{resolveDate(drawing.date)}</div>
          <div className="font-thin">{resolveStudentName(drawing.sender)}</div>
          <div className="font-thin">{drawing.studentId}</div>
        </div>

        <div
          onClick={(e) => resolveDownload(e, drawing)}
          className="ml-auto opacity-50 hover:opacity-100 h-full"
        >
          <DownloadIcon />
        </div>

        <div className="">
          {activeUser.isInstructor && (
            <div
              onClick={(e) => resolveDelete(e, drawing)}
              className="ml-2 mr-2 opacity-50 hover:opacity-100"
            >
              <DeleteOutlineIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
